import React, { Suspense } from "react";
import ProtectedRoute from "../privateRoute/privateRoutes";
import PaymentRoutes from "../privateRoute/PaymentRoutes";
import Spinner from "../Utils/Spinner";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { ResumePopupContextProvider } from "../Context/resumePopupContext";

import { ResumeUpdateContextProvider } from "../Context/resumeUpdateContext";
import { CoverUpdateContextProvider } from "../Context/coverUpdateContext";
import { UserTypeContextProvider } from "../Context/FreeOrProContext";
import { PageLayoutContextProvider } from "../Context/PageLayoutContext";
import { JsonEditorContextProvider } from "../Context/jsonEditorContext";
import { TemplateContextProvider } from "../Context/templateContext";
import { ExternalUserProvider } from "../Context/externalUserContext";
import AuthVerify from "./AuthVerify";

const Signup = React.lazy(() => import("../Pages/SignupPage/SignupPage"));
const LoginPage = React.lazy(() => import("../Pages/Login/LoginPage"));
const ForgotPassword = React.lazy(() =>
  import("../Pages/ForgotPassword/ForgotPassword")
);
const ChangePassword = React.lazy(() =>
  import("../Pages/ChangePassword/ChangePasswordPage")
);
const Landingpage = React.lazy(() =>
  import("../Pages/Landingpage/Landingpage")
);
const Dashboard = React.lazy(() => import("../Pages/Dashboard/Dashboard"));
const NoMatch = React.lazy(() => import("../Pages/404Page/404Page"));
const Google = React.lazy(() => import("../Pages/SocialPage/Google/Google"));
const RedirectUser = React.lazy(() => import("../Pages/Redirect/Redirect"));
const Unsubscripe = React.lazy(() =>
  import("../Pages/Unsubscripe/Unsubscripe")
);
const Resumetemplates = React.lazy(() =>
  import("../Pages/Resumetemplates/Resumetemplates")
);
const Resumeexample = React.lazy(() =>
  import("../Pages/Resumeexample/Resumeexample")
);
const Editor = React.lazy(() => import("../Pages/Editor/Editor"));
const ImageGenerate = React.lazy(() => import("../Pages/Editor/ImageGenerate"));
const ReviewImageGenerate = React.lazy(() =>
  import("../Pages/Editor/ReviewImageGenerate")
);
const Preview = React.lazy(() => import("../Pages/Preview/Preview"));
const CoverPreview = React.lazy(() =>
  import("../Pages/CoverPreview/CoverPreview")
);
const CoverLetter = React.lazy(() => import("../Pages/CoverEditor/Editor"));
const Accountsettings = React.lazy(() =>
  import("../Pages/Accountsettings/Accountsettings")
);
const Pricingplans = React.lazy(() =>
  import("../Pages/Pricingplans/Pricingplans")
);
const Payment = React.lazy(() => import("../Components/Payment/Stripe/Stripe"));
const Onboarding = React.lazy(() => import("../Pages/Onboarding/Onboarding"));
const PaymentSuccess = React.lazy(() =>
  import("../Pages/PaymentRedirects/Success/Success")
);
const PaymentFailure = React.lazy(() =>
  import("../Pages/PaymentRedirects/Failure/Failure")
);

function Routes() {
  const history = useHistory();
  const loading = useSelector((state) => state.loading);
  const resumeLoading = useSelector((state) => state.resumeLoading);
  return (
    <>
      {loading.loading ? <Spinner /> : resumeLoading.loading ? <Spinner /> : ""}
      <Router>
        <UserTypeContextProvider>
          <ResumePopupContextProvider>
            <PageLayoutContextProvider>
              <JsonEditorContextProvider>
                <ResumeUpdateContextProvider>
                  <CoverUpdateContextProvider>
                    <ExternalUserProvider>
                      <Suspense fallback={<Spinner />}>
                        <Switch>
                          <Route exact path="/">
                            <TemplateContextProvider>
                              <Onboarding />
                            </TemplateContextProvider>
                          </Route>
                          <Route exact path="/login">
                            <LoginPage />
                          </Route>
                          <Route exact path="/forgot_password">
                            <ForgotPassword />
                          </Route>
                          <Route exact path="/signup">
                            <Signup />
                          </Route>
                          <Route exact path="/onboarding">
                            <Onboarding />
                          </Route>
                          <Route exact path="/change_password">
                            <ChangePassword />
                          </Route>
                          <Route path="/google">
                            <Google />
                          </Route>
                          <Route path="/facebook">
                            <Google />
                          </Route>
                          <Route path="/linkedin">
                            <Google />
                          </Route>
                          <Route path="/create-resume/sample/:id">
                            <TemplateContextProvider>
                              <Editor />
                            </TemplateContextProvider>
                          </Route>
                          <Route path="/create-cover/sample/:id">
                            <TemplateContextProvider>
                              <CoverLetter />
                            </TemplateContextProvider>
                          </Route>
                          <Route path="/create-resume/home">
                            <TemplateContextProvider>
                              <Dashboard />
                            </TemplateContextProvider>
                          </Route>
                          <ProtectedRoute
                            exact
                            path="/home"
                            component={Dashboard}
                          />
                          <ProtectedRoute
                            path="/myaccount"
                            component={Accountsettings}
                          />
                          <ProtectedRoute
                            path="/resumes/:id"
                            component={Editor}
                          />
                          <ProtectedRoute
                            path="/coverletter/:id"
                            component={CoverLetter}
                          />
                          <ProtectedRoute
                            exact
                            path="/resume-templates"
                            component={Resumetemplates}
                          />
                          <ProtectedRoute
                            exact
                            path="/resume-examples"
                            component={Resumeexample}
                          />
                          <ProtectedRoute
                            exact
                            path="/paymentsuccess"
                            component={PaymentSuccess}
                          />
                          <ProtectedRoute
                            exact
                            path="/paymentfailure"
                            component={PaymentFailure}
                          />
                          <ProtectedRoute
                            exact
                            path="/pricingplans"
                            component={Pricingplans}
                          />
                          <PaymentRoutes
                            exact
                            path="/payment/:id"
                            component={Payment}
                          />
                          <Route
                            path="/preview/:id"
                            component={Editor}
                            type={"preview"}
                          />
                          <Route
                            path="/previewimageGenerate/:sample/:template"
                            component={ImageGenerate}
                            type={"preview"}
                          />
                          <Route path="/reviewimagegenerate/:sample/:template">
                            <TemplateContextProvider>
                              <ReviewImageGenerate />
                            </TemplateContextProvider>
                          </Route>
                          <Route
                            path="/preview1/:id"
                            component={Editor}
                            type={"preview"}
                          />
                          <Route path="/redirect" component={RedirectUser} />
                          <Route path="/unsubscripe" component={Unsubscripe} />
                          <Route
                            path="/coverpreview/:id"
                            component={CoverLetter}
                          />
                          <Route path="/qr/:id" component={Editor} />
                          <Route path="/qrcover/:id" component={CoverLetter} />

                          <Route path="*" component={NoMatch} />
                        </Switch>
                      </Suspense>
                    </ExternalUserProvider>
                  </CoverUpdateContextProvider>
                </ResumeUpdateContextProvider>
              </JsonEditorContextProvider>
            </PageLayoutContextProvider>
          </ResumePopupContextProvider>
        </UserTypeContextProvider>
        <AuthVerify data={true} />
      </Router>
    </>
  );
}

export default Routes;
