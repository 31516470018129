import axios from "axios";
import { useEffect } from "react";
import {
  LOADING_REQUEST,
  LOADING_SUCCESS,
  RESUME_LOADING_REQUEST,
  RESUME_LOADING_SUCCESS,
  EDITOR_LOADING_REQUEST,
  EDITOR_LOADING_SUCCESS,
  EDITOR_LOADING_FAIL,
} from "../Constants/LoadingConstants";

import { RESUME_DATA_UPDATE_SUCCESS } from "../Constants/ResumeConstants";

import { templateGetParticularActions } from "./TemplateActions";
import { isJobPresent } from "./ProfileActions";
import { updateLocal } from "../../Utils/updateLocalStorage";
import { UNDOREDO_ADD, EDITOR_DATA_ADD } from "../Constants/EditorConstants";
import {
  RESUME_CREATE_REQUEST,
  RESUME_CREATE_SUCCESS,
  RESUME_CREATE_FAIL,
  GET_RESUME_REQUEST,
  GET_RESUME_SUCCESS,
  GET_RESUME_FAIL,
  GET_RESUMEBYID_REQUEST,
  GET_RESUMEBYID_SUCCESS,
  GET_RESUMEBYID_FAIL,
  GET_RESUMEBYID_RESET,
  RESUME_DUPLICATE_REQUEST,
  RESUME_DUPLICATE_SUCCESS,
  RESUME_DUPLICATE_FAIL,
  RESUME_DELETE_REQUEST,
  RESUME_DELETE_SUCCESS,
  RESUME_DELETE_FAIL,
  RESUME_UPDATE_REQUEST,
  RESUME_UPDATE_SUCCESS,
  RESUME_UPDATE_FAIL,
  RESUME_DOWNLOAD_REQUEST,
  RESUME_DOWNLOAD_SUCCESS,
  RESUME_DOWNLOAD_FAIL,
  RESUME_DUPLICATE_TITLE_REQUEST,
  RESUME_DUPLICATE_TITLE_SUCCESS,
  RESUME_DUPLICATE_TITLE_FAIL,
  RESUME_NAME_CHANGE_REQUEST,
  RESUME_NAME_CHANGE_SUCCESS,
  RESUME_NAME_CHANGE_FAIL,
  RESUME_LAST_UPDATED_AT,
  RESUME_PREVIEW_REQUEST,
  RESUME_PREVIEW_SUCCESS,
  RESUME_PREVIEW_FAIL,
  ADMIN_LIST_SUCCESS,
  ADMIN_LIST_FAIL,
  RESUME_IMAGE_REQUEST,
  RESUME_IMAGE_SUCCESS,
  RESUME_IMAGE_FAIL,
  TEMP_RESUME_CREATE_SUCCESS,
  TEMP_RESUME_CREATE_FAIL,
  TEMP_RESUME_GET_SUCCESS,
  TEMP_RESUME_GET_FAIL,
  RESUME_UPDATETOREDIS_REQUEST,
  RESUME_UPDATETOREDIS_SUCCESS,
  RESUME_UPDATETOREDIS_FAIL,
  GET_RESUME_HISTORY_SUCCESS,
  GET_RESUME_HISTORY_FAIL,
} from "../Constants/ResumeConstants";

import {
  ACHIEVEMENTS_ADD,
  EDUCATION_ADD,
  INTEREST_ADD,
  LANGUAGES_ADD,
  POSITION_CHANGE,
  PROFILE_ADD,
  SKILLS_ADD,
  SUMMARY_ADD,
  WORK_EXPERIENCE_ADD,
  CUSTOM_TEXT_SECTION_ADD,
  CUSTOM_ONE_DATE_SECTION_ADD,
  CUSTOM_TWO_DATE_SECTION_ADD,
  CUSTOM_THREE_DATE_SECTION_ADD,
  CUSTOM_FOUR_DATE_SECTION_ADD,
  CUSTOM_FIVE_DATE_SECTION_ADD,
  CUSTOM_SIX_DATE_SECTION_ADD,
  CUSTOM_SEVEN_DATE_SECTION_ADD,
  CUSTOM_EIGHT_DATE_SECTION_ADD,
  CUSTOM_NINE_DATE_SECTION_ADD,
  CUSTOM_TEN_DATE_SECTION_ADD,
  CUSTOM_ONE_TEXT_SECTION_ADD,
  POSITION_ADD,
  LAYOUT_ADD,
  CALCULATE_HEIGHT,
  FROM_ADD,
  TO_ADD,
  CONTENT_ADD,
  SETTINGS_ADD,
} from "../Constants/EditorConstants";

import { COLORCHANGE_SUCCESS } from "../Constants/CustomizationConstants";

const baseurl = process.env.REACT_APP_API_URL;

export const resumeGetHistory = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    const {
      userData: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${baseurl}/api/getResumeHistory/${id}`,
      config
    );

    dispatch({
      type: GET_RESUME_HISTORY_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });

    dispatch({
      type: GET_RESUME_HISTORY_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const resumeUpdateToServerFromRedis =
  (resume_id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EDITOR_LOADING_REQUEST,
        loading: true,
      });
      dispatch({
        type: RESUME_UPDATETOREDIS_REQUEST,
        loading: true,
      });
      const {
        userData: { userInfo },
      } = getState();
      const userId = userInfo.user.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseurl}/api/updateFromDbToServer/${resume_id}/${userId}`,
        config
      );

      dispatch({
        type: RESUME_UPDATETOREDIS_SUCCESS,
        payload: data,
      });
      dispatch({
        type: EDITOR_LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: EDITOR_LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: RESUME_UPDATETOREDIS_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const tempDataDelete = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.delete(
      `${baseurl}/api/deleteTemporaryResume/${id}`,
      config
    );

    dispatch({
      type: TEMP_RESUME_GET_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });

    dispatch({
      type: TEMP_RESUME_GET_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const userTempResumeGet = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${baseurl}/api/getTemporaryResume/${email}`,
      config
    );

    dispatch({
      type: TEMP_RESUME_GET_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });

    dispatch({
      type: TEMP_RESUME_GET_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const tempResumeAdd = (content, type) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const document_size = JSON.parse(localStorage.getItem("document_size"));
    let createData = content;
    createData.isLetter = document_size && document_size == "letter" ? 1 : 0;

    const { data } = await axios.post(
      `${baseurl}/api/temp_data/${type}`,
      createData,
      config
    );

    dispatch({
      type: TEMP_RESUME_CREATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });

    dispatch({
      type: TEMP_RESUME_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const previewImage = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    dispatch({
      type: RESUME_IMAGE_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    // https://eunu2rfeea.execute-api.ap-south-1.amazonaws.com/?id=125
    // const { data } = await axios.get(`${baseurl}/api/preview/image/${id}`);
    const data = await axios.get(
      `https://eunu2rfeea.execute-api.ap-south-1.amazonaws.com?id=${id}`
    );

    dispatch({
      type: RESUME_IMAGE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });

    dispatch({
      type: RESUME_IMAGE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const previewResume = (id, download) => async (dispatch, getState) => {
  try {
    // dispatch({
    //   type: LOADING_REQUEST,
    //   loading: true,
    // });

    dispatch({
      type: RESUME_PREVIEW_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    let data;
    if (download == "download") {
      data = await axios.get(
        `${baseurl}/api/resume/image/${userId}/${id}/download`,
        config
      );
      dispatch({
        type: RESUME_PREVIEW_SUCCESS,
        payload: data,
      });
    } else {
      data = await axios.get(
        `${baseurl}/api/resume/image/${userId}/${id}`,
        config
      );
      dispatch({
        type: RESUME_PREVIEW_SUCCESS,
        payload: data,
      });
    }

    // dispatch({
    //   type: LOADING_SUCCESS,
    //   loading: false,
    // });
  } catch (error) {
    // dispatch({
    //   type: LOADING_SUCCESS,
    //   loading: false,
    // });

    dispatch({
      type: RESUME_PREVIEW_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const resumeNameChange = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    dispatch({
      type: RESUME_NAME_CHANGE_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${baseurl}/api/resume/${userId}`,
      name,
      config
    );

    dispatch({
      type: RESUME_NAME_CHANGE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });

    dispatch({
      type: RESUME_NAME_CHANGE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const resumeDownload = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });

    dispatch({
      type: RESUME_DOWNLOAD_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `http://65.1.42.105:3050/api/resume/image/${id}`,
      config
    );

    dispatch({
      type: RESUME_DOWNLOAD_SUCCESS,
      payload: data,
    });

    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });

    dispatch({
      type: RESUME_DOWNLOAD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const createResumes =
  (content, userFields) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });

      dispatch({
        type: RESUME_CREATE_REQUEST,
        loading: true,
      });

      const {
        userData: { userInfo },
      } = getState();

      const userId = userFields
        ? userFields && userFields.data.id
        : userInfo.user.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            userFields ? userFields.token : userInfo.token
          }`,
        },
      };
      const document_size = JSON.parse(localStorage.getItem("document_size"));
      let createData = content;
      createData.isLetter = document_size && document_size == "a4" ? 0 : 1;

      const { data } = await axios.post(
        `${baseurl}/api/resume/${userId}`,
        createData,
        config
      );

      dispatch({
        type: RESUME_CREATE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });

      dispatch({
        type: RESUME_CREATE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const getAllResumes = (sort, method) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    //   dispatch({
    //     type: RESUME_LOADING_REQUEST,
    //     loading: true,
    //   });
    dispatch({
      type: GET_RESUME_REQUEST,
      loading: true,
    });

    const {
      userData: { userInfo },
    } = getState();
    const userId = userInfo.user.id;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    if (sort == "infinite") {
      const { data } = await axios.get(
        `${baseurl}/api/resume/userall/${userId}?page=${method}`,
        config
      );
      let homeResumes = {};
      homeResumes.data = [];

      homeResumes.total = data && data.data.total;
      homeResumes.next_page_url = data && data.data.next_page_url;
      homeResumes.current_page = data && data.data.current_page;
      homeResumes.last_page = data && data.data.last_page;
      homeResumes.last_page_url = data && data.data.last_page_url;
      if (data && data.data && data.data.data) {
        data.data.data.forEach((res) => {
          let editorData = JSON.parse(res.content);
          editorData.type = res.type;
          editorData.id = res.id;
          editorData.title = res.title;
          editorData.updated_at = res.updated_at;
          editorData.template_id = res.template_id;
          editorData.random_code = res.random_code;
          homeResumes.data.push(editorData);
        });
      } else {
        // If data or its nested properties are not present, keep the array empty
        homeResumes.data = [];
      }

      dispatch({
        type: GET_RESUME_SUCCESS,
        payload: homeResumes,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: RESUME_LOADING_SUCCESS,
        loading: false,
      });
    } else if (
      sort != "initial" &&
      sort !== "" &&
      method != "search" &&
      method != ""
    ) {
      const { data } = await axios.get(
        `${baseurl}/api/resume/search/${userId}/?sort=${sort}&direction=${method}`,
        config
      );
      let homeResumes = {};
      homeResumes.data = [];
      homeResumes.total = data && data.data.total;
      homeResumes.next_page_url = data && data.data.next_page_url;
      homeResumes.current_page = data && data.data.current_page;
      homeResumes.last_page = data && data.data.last_page;
      homeResumes.last_page_url = data && data.data.last_page_url;
      if (data && data.data && data.data.data) {
        data.data.data.forEach((res) => {
          let editorData = JSON.parse(res.content);
          editorData.type = res.type;
          editorData.id = res.id;
          editorData.title = res.title;
          editorData.updated_at = res.updated_at;
          editorData.template_id = res.template_id;
          editorData.random_code = res.random_code;
          homeResumes.data.push(editorData);
        });
      } else {
        // If data or its nested properties are not present, keep the array empty
        homeResumes.data = [];
      }

      dispatch({
        type: GET_RESUME_SUCCESS,
        payload: homeResumes,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: RESUME_LOADING_SUCCESS,
        loading: false,
      });
    } else if (
      sort != "initial" &&
      sort &&
      sort.length > 0 &&
      method == "search"
    ) {
      const { data } = await axios.get(
        `${baseurl}/api/resume/search/${userId}?search=${sort}`,
        config
      );
      let homeResumes = {};
      homeResumes.data = [];
      homeResumes.total = data && data.data.total;
      homeResumes.next_page_url = data && data.data.next_page_url;
      homeResumes.current_page = data && data.data.current_page;
      homeResumes.last_page = data && data.data.last_page;
      homeResumes.last_page_url = data && data.data.last_page_url;
      if (data && data.data && data.data.data) {
        data.data.data.forEach((res) => {
          let editorData = JSON.parse(res.content);
          editorData.type = res.type;
          editorData.id = res.id;
          editorData.title = res.title;
          editorData.updated_at = res.updated_at;
          editorData.template_id = res.template_id;
          editorData.random_code = res.random_code;
          homeResumes.data.push(editorData);
        });
      } else {
        // If data or its nested properties are not present, keep the array empty
        homeResumes.data = [];
      }

      dispatch({
        type: GET_RESUME_SUCCESS,
        payload: homeResumes,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: RESUME_LOADING_SUCCESS,
        loading: false,
      });
    } else {
      const { data } = await axios.get(
        `${baseurl}/api/resume/userall/${userId}`,
        config
      );
      let homeResumes = {};
      homeResumes.data = [];
      homeResumes.total = data && data.data.total;
      homeResumes.next_page_url = data && data.data.next_page_url;
      homeResumes.current_page = data && data.data.current_page;
      homeResumes.last_page = data && data.data.last_page;
      homeResumes.last_page_url = data && data.data.last_page_url;
      if (data && data.data && data.data.data) {
        data.data.data.forEach((res) => {
          let editorData = JSON.parse(res.content);
          editorData.type = res.type;
          editorData.id = res.id;
          editorData.title = res.title;
          editorData.updated_at = res.updated_at;
          editorData.template_id = res.template_id;
          editorData.random_code = res.random_code;
          homeResumes.data.push(editorData);
        });
      } else {
        // If data or its nested properties are not present, keep the array empty
        homeResumes.data = [];
      }

      dispatch({
        type: GET_RESUME_SUCCESS,
        payload: homeResumes,
      });

      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: RESUME_LOADING_SUCCESS,
        loading: false,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RESUME_FAIL,
      payload: error.message,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: RESUME_LOADING_SUCCESS,
      loading: false,
    });
  }
};

export const sampleResumeById =
  (id, editData, isLogin) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });

      const {
        userData: { userInfo },
        resumeGetById,
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (userInfo) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${userInfo.token}`,
        };
      }

      if (isLogin != "isAuth") {
        var url = "";

        if (isLogin == "template") {
          url = `${baseurl}/api/sample/detail/${id}?template=${editData}`;
        } else {
          url = `${baseurl}/api/sample/detail/${id}`;
        }

        const { data } = await axios.get(url, config);
        if (isLogin == "template") {
          dispatch(templateGetParticularActions(editData));
        } else {
          dispatch(templateGetParticularActions(data.data.template_id));
        }

        const editorData = JSON.parse(data.data.content);

        if (
          data &&
          data.data &&
          data.data.type &&
          data.data.type === "cover_letter"
        ) {
          dispatch({
            type: GET_RESUMEBYID_SUCCESS,
            payload: data,
          });
        } else {
          const undoRedoInitial = {
            history: {
              timeline: [{ resumeData: data.data }],
              currentIndex: 0,
            },
          };
          dispatch({
            type: UNDOREDO_ADD,
            payload: undoRedoInitial,
          });
          let onBoarding_object = JSON.parse(
            localStorage.getItem("onBoarding")
          );
          let changedData = editorData;
          if (
            onBoarding_object &&
            onBoarding_object.Name &&
            onBoarding_object.Name != ""
          ) {
            changedData.profile.data[0].name =
              onBoarding_object &&
              onBoarding_object.Name &&
              onBoarding_object.Name;
            changedData.profile.data[0].jobTitle =
              onBoarding_object &&
              onBoarding_object.Name &&
              onBoarding_object.jobTitle;
          }
          dispatch({
            type: EDITOR_DATA_ADD,
            payload: changedData,
          });
          dispatch({
            type: LAYOUT_ADD,
            payload: editorData.layout,
          });
          dispatch({
            type: LOADING_SUCCESS,
            payload: false,
          });
          const user_settings = {
            job_title: data && data.data && data.data.job_title,
            job_category: data && data.data && data.data.category,
          };
          if (
            data &&
            data.data &&
            data.data.job_title &&
            data.data.job_title != ""
          ) {
            updateLocal(user_settings);
          }
          let tempData = data;
          tempData["data"]["template_id"] = data.data.template_id;
          dispatch({
            type: GET_RESUMEBYID_SUCCESS,
            payload: data,
          });
        }
        dispatch({
          type: RESUME_LAST_UPDATED_AT,
          payload: data,
        });
        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
        dispatch({
          type: CALCULATE_HEIGHT,
          payload: true,
        });
      } else {
        var url = "";

        url = `${baseurl}/api/sample/detail/${id}`;

        const { data } = await axios.get(url, config);
        //   dispatch(templateGetParticularActions(editData.template_id));

        // const editorData = JSON.parse(data.data.content);
        if (
          data &&
          data.data &&
          data.data.type &&
          data.data.type === "cover_letter"
        ) {
          //   dispatch({
          //     type: GET_RESUMEBYID_SUCCESS,
          //     payload: data,
          //   });
        } else {
          dispatch({
            type: EDITOR_DATA_ADD,
            payload: editData,
          });
          let tempData = data;
          tempData["data"]["template_id"] = editData.template_id
            ? editData.template_id
            : resumeGetById.resume.template_id;

          dispatch({
            type: GET_RESUMEBYID_SUCCESS,
            payload: tempData,
          });
        }
        dispatch({
          type: RESUME_LAST_UPDATED_AT,
          payload: data,
        });
        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
        dispatch({
          type: CALCULATE_HEIGHT,
          payload: true,
        });
      }
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: GET_RESUMEBYID_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const getResumeById =
  (id, share, withoutid) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });
      // dispatch({
      // 	type: GET_RESUMEBYID_REQUEST,
      // 	loading: true
      // })

      const {
        userData: { userInfo },
      } = getState();

      const userId = userInfo && userInfo.user.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (userInfo) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${userInfo.token}`,
        };
      }
      var url = "";
      if (withoutid) {
        url = `${baseurl}/api/resume/detail/${id}`;
      } else if (userId) {
        if (share == "qr") {
          url = `${baseurl}/api/resume/${userId}/${id}?share=${true}`;
        } else {
          url = `${baseurl}/api/resume/${userId}/${id}`;
        }
      } else {
        if (share == "qr") {
          // url = `${baseurl}/api/resume/${userId}/${id}?share=${true}`
          url = `${baseurl}/api/resume/detail/${id}?share=${true}`;
        } else {
          url = `${baseurl}/api/resume/detail/${id}`;
        }
      }
      const { data } = await axios.get(url, config);

      dispatch(
        templateGetParticularActions(data && data.data && data.data.template_id)
      );

      const editorData = JSON.parse(data.data.content);

      if (share != "undo") {
        const undoRedoInitial = {
          history: {
            timeline: [{ resumeData: data.data }],
            currentIndex: 0,
          },
        };
        dispatch({
          type: UNDOREDO_ADD,
          payload: undoRedoInitial,
        });
      }
      dispatch({
        type: EDITOR_DATA_ADD,
        payload: editorData,
      });
      dispatch({
        type: LAYOUT_ADD,
        payload: editorData.layout,
      });
      //   dispatch({
      //     type: LOADING_SUCCESS,
      //     payload: false,
      //   });
      let reumedatatomodify = data;
      reumedatatomodify.data.isLetter = parseInt(data?.data?.isLetter);
      dispatch({
        type: GET_RESUMEBYID_SUCCESS,
        payload: reumedatatomodify,
      });

      dispatch({
        type: RESUME_LAST_UPDATED_AT,
        payload: data,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: CALCULATE_HEIGHT,
        payload: true,
      });
      if (editorData && editorData.profile) {
        dispatch(
          isJobPresent(
            editorData &&
              editorData.profile &&
              editorData.profile.data &&
              editorData.profile.data[0] &&
              editorData.profile.data[0].jobTitle
          )
        );
      }
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: GET_RESUMEBYID_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const resumeEditActions =
  (id, content, customization) => async (dispatch, getState) => {
    if (typeof id === "undefined") {
      return;
    }
    const path = window.location.href;

    try {
      dispatch({
        type: EDITOR_LOADING_REQUEST,
        loading: true,
      });

      if (path && !path.includes("resumes")) {
        dispatch({
          type: LOADING_REQUEST,
          loading: true,
        });
      }
      const {
        userData: { userInfo },
        undoRedo,
      } = getState();

      if (path && path.includes("reviewimagegenerate")) {
        let resData = {};
        resData.data = content;
        dispatch({
          type: GET_RESUMEBYID_SUCCESS,
          payload: resData,
        });
        dispatch({
          type: CALCULATE_HEIGHT,
          payload: true,
        });
      }

      if (path && path.includes("create") && customization != "undo") {
        let undodata = undoRedo;
        undodata.history.timeline.push({
          resumeData: content,
          template_id: content.template_id,
        });
        undodata.history.currentIndex = undodata.history.timeline.length - 1;
        dispatch({
          type: UNDOREDO_ADD,
          payload: undodata,
        });
        if (customization == "sizeHandle") {
          let resData = {};
          resData.data = content;

          resData.data.isLetter = parseInt(content?.isLetter);
          dispatch({
            type: GET_RESUMEBYID_SUCCESS,
            payload: resData,
          });
        }

        dispatch({
          type: CALCULATE_HEIGHT,
          payload: true,
        });
      }

      const userId = userInfo.user.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseurl}/api/resume/${userId}/${id}`,
        content,
        config
      );

      //   if (JSON.parse(content && content.content)) {
      //     dispatch({
      //       type: EDITOR_DATA_ADD,
      //       payload: JSON.parse(content.content),
      //     });
      //   }

      if (customization != "undo" && path && path.includes("resumes")) {
        let undodata = undoRedo;
        undodata.history.timeline.push({
          resumeData: content,
        });
        undodata.history.currentIndex = undodata.history.timeline.length - 1;
        dispatch({
          type: UNDOREDO_ADD,
          payload: undodata,
        });
      }

      let reumedatatomodify = data;
      reumedatatomodify.data.isLetter = parseInt(data?.data?.isLetter);
      dispatch({
        type: GET_RESUMEBYID_SUCCESS,
        payload: reumedatatomodify,
      });
      dispatch({
        type: RESUME_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: EDITOR_LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: RESUME_LAST_UPDATED_AT,
        payload: data,
      });
      dispatch({
        type: RESUME_DATA_UPDATE_SUCCESS,
        payload: { loading: true },
      });
      if (path && !path.includes("resumes")) {
        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
      }
    } catch (error) {
      dispatch({
        type: EDITOR_LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: RESUME_DATA_UPDATE_SUCCESS,
        payload: { loading: false },
      });
      if (path && !path.includes("resumes")) {
        dispatch({
          type: LOADING_SUCCESS,
          loading: false,
        });
      }
      dispatch({
        type: RESUME_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteResumeByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    dispatch({
      type: RESUME_DELETE_REQUEST,
      loading: true,
    });
    const {
      userData: { userInfo },
    } = getState();
    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.delete(
      `${baseurl}/api/resume/${userId}/${id}`,
      config
    );

    dispatch({
      type: RESUME_DELETE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: RESUME_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const resumeDuplicate =
  (userId, id, title) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_REQUEST,
        loading: true,
      });
      dispatch({
        type: RESUME_DUPLICATE_REQUEST,
        loading: true,
      });
      const {
        userData: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${baseurl}/api/resume/duplicate/${userId}/${id}`,
        title,
        config
      );

      dispatch({
        type: RESUME_DUPLICATE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: LOADING_SUCCESS,
        loading: false,
      });
      dispatch({
        type: RESUME_DUPLICATE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      });
    }
  };

export const duplicateTitle = (title) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_REQUEST,
      loading: true,
    });
    dispatch({
      type: RESUME_DUPLICATE_TITLE_REQUEST,
      loading: true,
    });
    const {
      userData: { userInfo },
    } = getState();

    const userId = userInfo.user.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${baseurl}/api/resume/title/${userId}/?title=${title}`,
      config
    );

    dispatch({
      type: RESUME_DUPLICATE_TITLE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_SUCCESS,
      loading: false,
    });
    dispatch({
      type: RESUME_DUPLICATE_TITLE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};
